<template lang="pug">
layout-sider(:width="550", :collapsible="false", className="sider-main")
  div(
    :style="{ margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '357px', height: '100vh' }"
  )
    div(:style="{ position: 'absolute', top: '0' }")
    logo(
      className="logo",
      mode="login",
      :customStyle="{ marginTop: '70px', marginBottom: '10px', maxHeight: '215px' }"
    )
    div
      .top-decorator
      AppH2(content="Iniciar sesión", className="regular inside-container")
      br
    a-form(:form="form", @submit.prevent="login")
      a-form-item
        app-text(
          content="Correo electrónico:",
          className="regular-spaced inside-container",
          :style="{ margin: '20px 0 20px' }"
        )
        a-input(
          v-decorator="['email',{ rules: [{required: true, message: 'El correo electrónico es necesario'}] }]",
          :disabled="isLoadingAuth"
        )
      a-form-item
        app-text(
          content="Contraseña:",
          className="regular-spaced inside-container",
          :style="{ margin: '20px 0 20px' }"
        )
        a-input(
          :disabled="isLoadingAuth",
          type="password",
          v-decorator="['password',{ rules: [{required: true, message: 'La contraseña es necesaria'}] }]"
        )
      div(
        :style="{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }"
      )
        app-text(
          v-show="errorAuth",
          :customStyle="{ marginRight: '5%' }",
          className="regular-error nano",
          :content="errorAuth"
        )
        app-text.form-link(
          content="Olvidé mi contraseña",
          @click="onclickRecoveryPassword"
        )
      app-button(
        :loading="isLoadingAuth",
        type="primary",
        label="Acceder",
        className="block",
        htmlType="submit",
        :customStyle="{ margin: '10% 0 20% 0' }"
      )
</template>
<script>
//Import atoms components
import { AuthService } from "@/common/api.service";
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppSelect from "@/components/atoms/AppSelect.vue";

//import molecules components
import Logo from "@/components/molecules/Logo.vue";

//Import organisms components
import LayoutSider from "@/components/organisms/LayoutSider.vue";

//Import Login functions
import { LOGIN_COMERCIAL } from "../../../store/actions.type";
import { mapGetters } from "vuex";

import colorService from "@/common/color.service";
import { THEME } from "@/store/actions.type";

export default {
  name: "LoginContainer",
  components: {
    LayoutSider,
    AppH2,
    AppText,
    AppButton,
    AppImage,
    AppSelect,
    Logo
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" })
    };
  },
  computed: {
    ...mapGetters(["isLoadingAuth", "errorAuth", "getThemeName"])
  },
  created() {
    let token = this.$route.query.verification_token;
    if (token) {
      AuthService.verifyVerificationToken(token)
        .then(() => {
          this.$message.success(
            "Cuenta Verificada puedes iniciar sesión con tus credenciales"
          );
        })
        .catch(() => {
          this.$message.error("El token expiró o es invalido");
        });
    }
  },
  beforeCreate: function() {
    if (this.$session.exists()) {
      let data = this.$session.getAll();
      if (data.ShowRoomClient !== null && data.ShowRoomClient !== undefined)
        this.$router.push({
          name: "ClientLobby"
        });
      else if (
        data.showRoomComercialId !== null &&
        data.showRoomComercialId !== undefined
      )
        this.$router.push({
          name: "Dashboard/Panel"
        });
    }
  },
  methods: {
    login: function() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const { email, password } = values;
          this.$store
            .dispatch(LOGIN_COMERCIAL, {
              username: email,
              password: password
            })
            .then(({ user }) => {
              if (user) {
                this.$session.start();
                this.$session.set("showRoomComercialId", 2);
                this.$session.set("user", user);
                this.$session.set("theme", user.brand.toLowerCase());
                this.$store.dispatch(THEME, user.brand.toLowerCase());
                this.$router.push({
                  name: "Dashboard/Panel"
                });
              }
            });
        }
      });
    },
    themeHandler(value) {
      colorService.changeTheme(value);
      this.$store.dispatch(THEME, value);
    },
    goToViewClient: function() {
      this.$router.push({ name: "LoginClient" });
    },
    onclickRecoveryPassword: function() {
      this.$router.push("/recoveryPassword");
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../../less/main.less";

.sider-text-container {
  width: 357px;
}
.form-link {
  cursor: pointer;
  font-size: 16px;
  color: var(--link-color);
  letter-spacing: 1px;
  margin: 0;
}
.form-link:hover {
  text-decoration: underline;
}

.ant-input {
  background: var(--container-color) !important;
  border: 0 !important;
  border-bottom: 3px solid var(--primary-color) !important;
  color: var(--container-text-color);
  border-radius: 0;
}
.top-decorator {
  height: 3px;
  background-color: var(--primary-color);
  width: 60px;
  margin-bottom: 15px;
}
// .btn-fluid {
//   margin-top: 20%;
//   height: 45px;
//   width: 100%;
//   font-size: 18px;
//   letter-spacing: 1px;
// }
</style>
