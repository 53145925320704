<template lang="pug">
Layout
  LoginContainer
  banner-image
</template>
<script>
//Import atoms components
import AppImage from "@/components/atoms/AppImage.vue";

//Import molecules
import BannerImage from "@/components/molecules/Banner.vue";

//Import organisms components
import Layout from "@/components/organisms/Layout.vue";

//Import customComponents from this component
import LoginContainer from "@/components/customComponents/LoginComercial/LoginContainer.vue";

import colorService from "@/common/color.service";
import { mapGetters } from "vuex";
export default {
  name: "LoginComercial",
  components: {
    Layout,
    LoginContainer,
    AppImage,
    BannerImage
  },
  computed: {
    ...mapGetters(["getThemeName"])
  },
  async beforeMount() {
    await colorService.changeTheme(this.getThemeName);
  }
};
</script>
